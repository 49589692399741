var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-page", { staticClass: "relative payment-create" }, [
    _c(
      "form",
      {
        attrs: { action: "" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.submitPaymentData($event)
          }
        }
      },
      [
        _c(
          "sw-page-header",
          { staticClass: "mb-5", attrs: { title: _vm.pageTitle } },
          [
            _c(
              "sw-breadcrumb",
              { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
              [
                _c("sw-breadcrumb-item", {
                  attrs: {
                    title: _vm.$t("general.home"),
                    to: "/admin/dashboard"
                  }
                }),
                _vm._v(" "),
                _c("sw-breadcrumb-item", {
                  attrs: {
                    title: _vm.$tc("payments.payment", 2),
                    to: "/admin/payments"
                  }
                }),
                _vm._v(" "),
                _vm.$route.name === "payments.edit"
                  ? _c("sw-breadcrumb-item", {
                      attrs: {
                        title: _vm.$t("payments.edit_payment"),
                        to: "#",
                        active: ""
                      }
                    })
                  : _c("sw-breadcrumb-item", {
                      attrs: {
                        title: _vm.$t("payments.new_payment"),
                        to: "#",
                        active: ""
                      }
                    })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "template",
              { slot: "actions" },
              [
                _c(
                  "sw-button",
                  {
                    staticClass: "hidden sm:flex",
                    attrs: {
                      loading: _vm.isLoading,
                      disabled: _vm.isLoading,
                      variant: "primary",
                      type: "submit",
                      size: "lg"
                    }
                  },
                  [
                    !_vm.isLoading
                      ? _c("save-icon", { staticClass: "mr-2 -ml-1" })
                      : _vm._e(),
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.isEdit
                            ? _vm.$t("payments.update_payment")
                            : _vm.$t("payments.save_payment")
                        ) +
                        "\n        "
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          2
        ),
        _vm._v(" "),
        _vm.isRequestOnGoing
          ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
          : _c(
              "sw-card",
              [
                _c(
                  "div",
                  { staticClass: "grid gap-6 grid-col-1 md:grid-cols-2" },
                  [
                    _c(
                      "sw-input-group",
                      {
                        attrs: {
                          label: _vm.$t("payments.date"),
                          error: _vm.DateError,
                          required: ""
                        }
                      },
                      [
                        _c("base-date-picker", {
                          staticClass: "mt-1",
                          attrs: {
                            invalid: _vm.$v.formData.payment_date.$error,
                            "calendar-button": true,
                            "calendar-button-icon": "calendar"
                          },
                          on: {
                            input: function($event) {
                              return _vm.$v.formData.payment_date.$touch()
                            }
                          },
                          model: {
                            value: _vm.formData.payment_date,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "payment_date", $$v)
                            },
                            expression: "formData.payment_date"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-input-group",
                      {
                        attrs: {
                          label: _vm.$t("payments.payment_number"),
                          error: _vm.paymentNumError,
                          required: ""
                        }
                      },
                      [
                        _c("sw-input", {
                          staticClass: "mt-1",
                          attrs: {
                            prefix: _vm.paymentPrefix + " - ",
                            invalid: _vm.$v.paymentNumAttribute.$error
                          },
                          on: {
                            input: function($event) {
                              return _vm.$v.paymentNumAttribute.$touch()
                            }
                          },
                          model: {
                            value: _vm.paymentNumAttribute,
                            callback: function($$v) {
                              _vm.paymentNumAttribute =
                                typeof $$v === "string" ? $$v.trim() : $$v
                            },
                            expression: "paymentNumAttribute"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-input-group",
                      {
                        attrs: {
                          label: _vm.$t("payments.customer"),
                          error: _vm.customerError,
                          required: ""
                        }
                      },
                      [
                        _c("sw-select", {
                          staticClass: "mt-1",
                          attrs: {
                            options: _vm.customers,
                            searchable: true,
                            "show-labels": false,
                            "allow-empty": false,
                            disabled: _vm.isEdit,
                            placeholder: _vm.$t("customers.select_a_customer"),
                            label: "name",
                            "track-by": "id"
                          },
                          model: {
                            value: _vm.customer,
                            callback: function($$v) {
                              _vm.customer = $$v
                            },
                            expression: "customer"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-input-group",
                      { attrs: { label: _vm.$t("payments.invoice") } },
                      [
                        _c("sw-select", {
                          staticClass: "mt-1",
                          attrs: {
                            options: _vm.invoiceList,
                            searchable: true,
                            "show-labels": false,
                            "allow-empty": false,
                            disabled: _vm.isEdit,
                            placeholder: _vm.$t("invoices.select_invoice"),
                            "custom-label": _vm.invoiceWithAmount,
                            "track-by": "invoice_number"
                          },
                          model: {
                            value: _vm.invoice,
                            callback: function($$v) {
                              _vm.invoice = $$v
                            },
                            expression: "invoice"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-input-group",
                      {
                        attrs: {
                          label: _vm.$t("payments.amount"),
                          error: _vm.amountError,
                          required: ""
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "relative w-full mt-1" },
                          [
                            _c("sw-money", {
                              staticClass:
                                "relative w-full focus:border focus:border-solid focus:border-primary-500",
                              attrs: {
                                currency: _vm.customerCurrency,
                                invalid: _vm.$v.formData.amount.$error
                              },
                              on: {
                                input: function($event) {
                                  return _vm.$v.formData.amount.$touch()
                                }
                              },
                              model: {
                                value: _vm.amount,
                                callback: function($$v) {
                                  _vm.amount = $$v
                                },
                                expression: "amount"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "sw-input-group",
                      { attrs: { label: _vm.$t("payments.payment_mode") } },
                      [
                        _c(
                          "sw-select",
                          {
                            staticClass: "mt-1",
                            attrs: {
                              options: _vm.paymentModes,
                              searchable: true,
                              "show-labels": false,
                              placeholder: _vm.$t(
                                "payments.select_payment_mode"
                              ),
                              "max-height": 150,
                              label: "name"
                            },
                            model: {
                              value: _vm.formData.payment_method,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "payment_method", $$v)
                              },
                              expression: "formData.payment_method"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                attrs: { slot: "afterList" },
                                slot: "afterList"
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "flex items-center justify-center w-full px-2 py-2 bg-gray-200 border-none outline-none text-primary-400",
                                    attrs: { type: "button" },
                                    on: { click: _vm.addPaymentMode }
                                  },
                                  [
                                    _c("shopping-cart-icon", {
                                      staticClass: "h-5 mr-3 text-primary-400"
                                    }),
                                    _vm._v(" "),
                                    _c("label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "settings.customization.payments.add_payment_mode"
                                          )
                                        )
                                      )
                                    ])
                                  ],
                                  1
                                )
                              ]
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.customFields.length > 0
                  ? _c("div", [
                      _c(
                        "div",
                        {
                          staticClass:
                            "grid gap-6 mt-6 grid-col-1 md:grid-cols-2"
                        },
                        _vm._l(_vm.customFields, function(field, index) {
                          return _c(
                            "sw-input-group",
                            {
                              key: index,
                              attrs: {
                                label: field.label,
                                required: field.is_required ? true : false
                              }
                            },
                            [
                              _c(field.type + "Field", {
                                tag: "component",
                                attrs: {
                                  type: field.type.label,
                                  field: field,
                                  "is-edit": _vm.isEdit,
                                  "invalid-fields": _vm.invalidFields
                                },
                                on: { update: _vm.setCustomFieldValue }
                              })
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "sw-popup",
                  {
                    ref: "notePopup",
                    staticClass:
                      "my-6 text-sm font-semibold leading-5 text-primary-400"
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "float-right mt-1",
                        attrs: { slot: "activator" },
                        slot: "activator"
                      },
                      [
                        _vm._v(
                          "\n          + " +
                            _vm._s(_vm.$t("general.insert_note")) +
                            "\n        "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("note-select-popup", {
                      attrs: { type: "Payment" },
                      on: { select: _vm.onSelectNote }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "sw-input-group",
                  {
                    staticClass: "mt-6 mb-4",
                    attrs: { label: _vm.$t("payments.note") }
                  },
                  [
                    _c("base-custom-input", {
                      staticClass: "mb-4",
                      attrs: { fields: _vm.PaymentFields },
                      model: {
                        value: _vm.formData.notes,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "notes", $$v)
                        },
                        expression: "formData.notes"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "sw-button",
                  {
                    staticClass: "flex w-full mt-4 sm:hidden md:hidden",
                    attrs: {
                      disabled: _vm.isLoading,
                      loading: _vm.isLoading,
                      variant: "primary",
                      type: "submit"
                    }
                  },
                  [
                    !_vm.isLoading
                      ? _c("save-icon", { staticClass: "mr-2 -ml-1" })
                      : _vm._e(),
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.isEdit
                            ? _vm.$t("payments.update_payment")
                            : _vm.$t("payments.save_payment")
                        ) +
                        "\n      "
                    )
                  ],
                  1
                )
              ],
              1
            )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }