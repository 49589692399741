export const UPDATE_APP_LOADING_STATUS = 'UPDATE_APP_LOADING_STATUS'

export const SET_LANGUAGES = 'SET_LANGUAGES'

export const SET_CURRENCIES = 'SET_CURRENCIES'

export const SET_DATE_FORMATS = 'SET_DATE_FORMATS'

export const SET_TIMEZONES = 'SET_TIMEZONES'

export const SET_FISCAL_YEARS = 'SET_FISCAL_YEARS'

export const SET_COUNTRIES = 'SET_COUNTRIES'

export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR'
