var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sw-switch", {
    staticClass: "btn-switch",
    staticStyle: { "margin-top": "-15px" },
    on: { change: _vm.onChange },
    model: {
      value: _vm.switchData,
      callback: function($$v) {
        _vm.switchData = $$v
      },
      expression: "switchData"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }