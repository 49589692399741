var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sw-select", {
    attrs: {
      options: _vm.inputOptions,
      taggable: true,
      "show-labels": false,
      label: "name",
      "track-by": "name"
    },
    on: {
      input: function($event) {
        return _vm.$emit("input", _vm.inputValue)
      }
    },
    model: {
      value: _vm.inputValue,
      callback: function($$v) {
        _vm.inputValue = $$v
      },
      expression: "inputValue"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }