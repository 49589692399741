var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tax-type-modal" }, [
    _c(
      "form",
      {
        attrs: { action: "" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.submitTaxTypeData($event)
          }
        }
      },
      [
        _c(
          "div",
          { staticClass: "p-8 sm:p-6" },
          [
            _c(
              "sw-input-group",
              {
                staticClass: "mt-3",
                attrs: {
                  label: _vm.$t("tax_types.name"),
                  error: _vm.nameError,
                  variant: "horizontal",
                  required: ""
                }
              },
              [
                _c("sw-input", {
                  ref: "name",
                  attrs: { invalid: _vm.$v.formData.name.$error, type: "text" },
                  on: {
                    input: function($event) {
                      return _vm.$v.formData.name.$touch()
                    }
                  },
                  model: {
                    value: _vm.formData.name,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "name", $$v)
                    },
                    expression: "formData.name"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "sw-input-group",
              {
                staticClass: "mt-3",
                attrs: {
                  label: _vm.$t("tax_types.percent"),
                  error: _vm.percentError,
                  variant: "horizontal",
                  required: ""
                }
              },
              [
                _c("sw-money", {
                  staticClass:
                    "relative w-full focus:border focus:border-solid focus:border-primary",
                  attrs: {
                    currency: _vm.defaultInput,
                    invalid: _vm.$v.formData.percent.$error
                  },
                  on: {
                    input: function($event) {
                      return _vm.$v.formData.percent.$touch()
                    }
                  },
                  model: {
                    value: _vm.formData.percent,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "percent", $$v)
                    },
                    expression: "formData.percent"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "sw-input-group",
              {
                staticClass: "mt-3",
                attrs: {
                  label: _vm.$t("tax_types.description"),
                  error: _vm.descriptionError,
                  variant: "horizontal"
                }
              },
              [
                _c("sw-textarea", {
                  attrs: { rows: "4", cols: "50" },
                  on: {
                    input: function($event) {
                      return _vm.$v.formData.description.$touch()
                    }
                  },
                  model: {
                    value: _vm.formData.description,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "description", $$v)
                    },
                    expression: "formData.description"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "sw-input-group",
              {
                staticClass: "mt-3",
                attrs: {
                  label: _vm.$t("tax_types.compound_tax"),
                  variant: "horizontal"
                }
              },
              [
                _c("sw-switch", {
                  staticClass: "flex items-center mt-1",
                  model: {
                    value: _vm.formData.compound_tax,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "compound_tax", $$v)
                    },
                    expression: "formData.compound_tax"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "z-0 flex justify-end p-4 border-t border-solid border--200 border-modal-bg"
          },
          [
            _c(
              "sw-button",
              {
                staticClass: "mr-3 text-sm",
                attrs: { variant: "primary-outline", type: "button" },
                on: { click: _vm.closeTaxModal }
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("general.cancel")) + "\n      "
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "sw-button",
              {
                attrs: {
                  loading: _vm.isLoading,
                  variant: "primary",
                  type: "submit"
                }
              },
              [
                !_vm.isLoading
                  ? _c("save-icon", { staticClass: "mr-2" })
                  : _vm._e(),
                _vm._v(
                  "\n        " +
                    _vm._s(
                      !_vm.isEdit
                        ? _vm.$t("general.save")
                        : _vm.$t("general.update")
                    ) +
                    "\n      "
                )
              ],
              1
            )
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }