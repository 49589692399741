var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      attrs: { id: "loginForm" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.validateBeforeSubmit($event)
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "mb-4 form-group" },
        [
          _c(
            "sw-input-group",
            { attrs: { label: _vm.$t("login.email"), required: "" } },
            [
              _c("sw-input", {
                attrs: {
                  invalid: _vm.$v.formData.email.$error,
                  placeholder: _vm.$t("login.enter_email"),
                  type: "email",
                  name: "email"
                },
                on: {
                  input: function($event) {
                    return _vm.$v.formData.email.$touch()
                  }
                },
                model: {
                  value: _vm.formData.email,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.formData,
                      "email",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "formData.email"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.$v.formData.email.$error
            ? _c("div", [
                !_vm.$v.formData.email.required
                  ? _c(
                      "span",
                      { staticClass: "text-sm help-block text-danger" },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("validation.required")) +
                            "\n      "
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.$v.formData.email.email
                  ? _c(
                      "span",
                      { staticClass: "text-sm help-block text-danger" },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("validation.email_incorrect")) +
                            "\n      "
                        )
                      ]
                    )
                  : _vm._e()
              ])
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mb-4 form-group" },
        [
          _c(
            "sw-input-group",
            { attrs: { label: _vm.$t("login.password"), required: "" } },
            [
              _c("sw-input", {
                attrs: {
                  id: "password",
                  invalid: _vm.$v.formData.password.$error,
                  placeholder: _vm.$t("login.enter_password"),
                  type: "password",
                  name: "password"
                },
                on: {
                  input: function($event) {
                    return _vm.$v.formData.password.$touch()
                  }
                },
                model: {
                  value: _vm.formData.password,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.formData,
                      "password",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "formData.password"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.$v.formData.password.$error
            ? _c("div", [
                !_vm.$v.formData.password.required
                  ? _c(
                      "span",
                      { staticClass: "text-sm help-block text-danger" },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("validation.required")) +
                            "\n      "
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.$v.formData.password.minLength
                  ? _c(
                      "span",
                      { staticClass: "text-sm help-block text-danger" },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              _vm.$tc(
                                "validation.password_length",
                                _vm.$v.formData.password.minLength.min,
                                {
                                  count:
                                    _vm.$v.formData.password.$params.minLength
                                      .min
                                }
                              )
                            ) +
                            "\n      "
                        )
                      ]
                    )
                  : _vm._e()
              ])
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mb-8 form-group" },
        [
          _c(
            "sw-input-group",
            { attrs: { label: _vm.$t("login.retype_password"), required: "" } },
            [
              _c("sw-input", {
                attrs: {
                  invalid: _vm.$v.formData.password_confirmation.$error,
                  placeholder: _vm.$t("login.retype_password"),
                  type: "password",
                  name: "password_confirmation"
                },
                on: {
                  input: function($event) {
                    return _vm.$v.formData.password_confirmation.$touch()
                  }
                },
                model: {
                  value: _vm.formData.password_confirmation,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.formData,
                      "password_confirmation",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "formData.password_confirmation"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.$v.formData.password_confirmation.$error
            ? _c("div", [
                !_vm.$v.formData.password_confirmation.sameAsPassword
                  ? _c(
                      "span",
                      { staticClass: "text-sm help-block text-danger" },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("validation.password_incorrect")) +
                            "\n      "
                        )
                      ]
                    )
                  : _vm._e()
              ])
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("sw-button", { attrs: { type: "submit", variant: "primary" } }, [
        _vm._v("\n    " + _vm._s(_vm.$t("login.reset_password")) + "\n  ")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }