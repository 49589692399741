var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "relative h-full",
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.updateUserData($event)
        }
      }
    },
    [
      _vm.isRequestOnGoing
        ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "sw-card",
        { attrs: { variant: "setting-card" } },
        [
          _c("template", { slot: "header" }, [
            _c("h6", { staticClass: "sw-section-title" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("settings.account_settings.account_settings")) +
                  "\n      "
              )
            ]),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "mt-2 text-sm leading-snug text-gray-500",
                staticStyle: { "max-width": "680px" }
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$t("settings.account_settings.section_description")
                    ) +
                    "\n      "
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "grid mb-4 md:grid-cols-6" }, [
            _c(
              "div",
              [
                _c(
                  "label",
                  {
                    staticClass:
                      "text-sm not-italic font-medium leading-4 text-black whitespace-nowrap"
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$tc("settings.account_settings.profile_picture")
                        ) +
                        "\n        "
                    )
                  ]
                ),
                _vm._v(" "),
                _c("sw-avatar", {
                  attrs: {
                    "preview-avatar": _vm.previewAvatar,
                    label: _vm.$tc("general.choose_file")
                  },
                  on: {
                    changed: _vm.onChange,
                    uploadHandler: _vm.onUploadHandler,
                    handleUploadError: _vm.onHandleUploadError
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "icon",
                      fn: function() {
                        return [
                          _c("cloud-upload-icon", {
                            staticClass:
                              "h-5 mb-2 text-xl leading-6 text-gray-400"
                          })
                        ]
                      },
                      proxy: true
                    }
                  ])
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "grid gap-6 sm:grid-col-1 md:grid-cols-2" },
            [
              _c(
                "sw-input-group",
                {
                  attrs: {
                    label: _vm.$tc("settings.account_settings.name"),
                    error: _vm.nameError
                  }
                },
                [
                  _c("sw-input", {
                    staticClass: "mt-2",
                    attrs: {
                      invalid: _vm.$v.formData.name.$error,
                      placeholder: _vm.$t("settings.user_profile.name")
                    },
                    on: {
                      input: function($event) {
                        return _vm.$v.formData.name.$touch()
                      }
                    },
                    model: {
                      value: _vm.formData.name,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "name", $$v)
                      },
                      expression: "formData.name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  attrs: {
                    label: _vm.$tc("settings.account_settings.email"),
                    error: _vm.emailError
                  }
                },
                [
                  _c("sw-input", {
                    staticClass: "mt-2",
                    attrs: {
                      invalid: _vm.$v.formData.email.$error,
                      placeholder: _vm.$t("settings.user_profile.email")
                    },
                    on: {
                      input: function($event) {
                        return _vm.$v.formData.email.$touch()
                      }
                    },
                    model: {
                      value: _vm.formData.email,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "email", $$v)
                      },
                      expression: "formData.email"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  attrs: {
                    label: _vm.$tc("settings.account_settings.password"),
                    error: _vm.passwordError
                  }
                },
                [
                  _c("sw-input", {
                    staticClass: "mt-2",
                    attrs: {
                      invalid: _vm.$v.formData.password.$error,
                      placeholder: _vm.$t("settings.user_profile.password"),
                      type: "password"
                    },
                    on: {
                      input: function($event) {
                        return _vm.$v.formData.password.$touch()
                      }
                    },
                    model: {
                      value: _vm.formData.password,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "password", $$v)
                      },
                      expression: "formData.password"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "mt-1 mb-2",
                  attrs: {
                    label: _vm.$tc(
                      "settings.account_settings.confirm_password"
                    ),
                    error: _vm.confirmPasswordError
                  }
                },
                [
                  _c("sw-input", {
                    attrs: {
                      invalid: _vm.$v.formData.confirm_password.$error,
                      placeholder: _vm.$t(
                        "settings.user_profile.confirm_password"
                      ),
                      type: "password"
                    },
                    on: {
                      input: function($event) {
                        return _vm.$v.formData.confirm_password.$touch()
                      }
                    },
                    model: {
                      value: _vm.formData.confirm_password,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "confirm_password", $$v)
                      },
                      expression: "formData.confirm_password"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "grid gap-6 mt-4 sm:grid-col-1 md:grid-cols-2" },
            [
              _c(
                "sw-input-group",
                {
                  attrs: {
                    label: _vm.$tc("settings.language"),
                    error: _vm.languageError
                  }
                },
                [
                  _c("sw-select", {
                    staticClass: "mt-2",
                    class: { error: _vm.$v.language.$error },
                    attrs: {
                      options: _vm.languages,
                      searchable: true,
                      "show-labels": false,
                      "allow-empty": false,
                      placeholder: _vm.$tc(
                        "settings.preferences.select_language"
                      ),
                      label: "name",
                      "track-by": "code"
                    },
                    model: {
                      value: _vm.language,
                      callback: function($$v) {
                        _vm.language = $$v
                      },
                      expression: "language"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-button",
            {
              staticClass: "mt-6",
              attrs: {
                loading: _vm.isLoading,
                disabled: _vm.isLoading,
                variant: "primary"
              }
            },
            [
              !_vm.isLoading
                ? _c("save-icon", { staticClass: "mr-2 -ml-1" })
                : _vm._e(),
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$tc("settings.account_settings.save")) +
                  "\n    "
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }