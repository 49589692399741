var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "sw-card",
    { attrs: { variant: "setting-card" } },
    [
      _c("template", { slot: "header" }, [
        _c("h6", { staticClass: "sw-section-title" }, [
          _vm._v(
            "\n      " + _vm._s(_vm.$t("settings.update_app.title")) + "\n    "
          )
        ]),
        _vm._v(" "),
        _c(
          "p",
          {
            staticClass: "mt-2 text-sm leading-snug text-gray-500",
            staticStyle: { "max-width": "680px" }
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("settings.update_app.description")) +
                "\n    "
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "m-0" },
        [
          _c(
            "label",
            { staticClass: "text-sm not-italic font-medium input-label" },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("settings.update_app.current_version")) +
                  "\n    "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "label",
            {
              staticClass:
                "box-border flex w-16 p-3 my-2 text-sm text-gray-500 bg-gray-200 border border-gray-200 border-solid rounded-md version"
            },
            [_vm._v("\n      " + _vm._s(_vm.currentVersion) + "\n    ")]
          ),
          _vm._v(" "),
          _c(
            "sw-button",
            {
              staticClass: "mt-6",
              attrs: {
                loading: _vm.isCheckingforUpdate,
                disabled: _vm.isCheckingforUpdate || _vm.isUpdating,
                variant: "primary-outline"
              },
              on: { click: _vm.checkUpdate }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("settings.update_app.check_update")) +
                  "\n    "
              )
            ]
          ),
          _vm._v(" "),
          _vm.isUpdateAvailable
            ? _c("sw-divider", { staticClass: "mt-2 mb-4" })
            : _vm._e(),
          _vm._v(" "),
          _vm.isUpdateAvailable
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isUpdating,
                      expression: "!isUpdating"
                    }
                  ],
                  staticClass: "mt-4 content"
                },
                [
                  _c("h6", { staticClass: "mb-8 sw-section-title" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("settings.update_app.avail_update")) +
                        "\n      "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass: "text-sm not-italic font-medium input-label"
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("settings.update_app.next_version")) +
                          " "
                      )
                    ]
                  ),
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass:
                        "box-border flex w-16 p-3 my-2 text-sm text-gray-500 bg-gray-200 border border-gray-200 border-solid rounded-md version"
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.updateData.version) +
                          "\n      "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", {
                    staticClass:
                      "pl-5 mt-4 mb-8 text-sm leading-snug text-gray-500 update-description",
                    staticStyle: {
                      "white-space": "pre-wrap",
                      "max-width": "480px"
                    },
                    domProps: { innerHTML: _vm._s(_vm.description) }
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass: "text-sm not-italic font-medium input-label"
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("settings.update_app.requirements")) +
                          "\n      "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "table",
                    {
                      staticClass:
                        "w-1/2 mt-2 border-2 border-gray-200 table-fixed"
                    },
                    _vm._l(_vm.requiredExtentions, function(ext, i) {
                      return _c(
                        "tr",
                        { key: i, staticClass: "p-2 border-2 border-gray-200" },
                        [
                          _c(
                            "td",
                            {
                              staticClass: "p-2 text-sm truncate",
                              attrs: { width: "70%" }
                            },
                            [
                              _vm._v(
                                "\n            " + _vm._s(i) + "\n          "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              staticClass: "p-2 text-sm text-right",
                              attrs: { width: "30%" }
                            },
                            [
                              ext
                                ? _c("span", {
                                    staticClass:
                                      "inline-block w-4 h-4 ml-3 mr-2 rounded-full bg-success"
                                  })
                                : _c("span", {
                                    staticClass:
                                      "inline-block w-4 h-4 ml-3 mr-2 rounded-full bg-danger"
                                  })
                            ]
                          )
                        ]
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-button",
                    {
                      staticClass: "mt-10",
                      attrs: { size: "lg", variant: "primary" },
                      on: { click: _vm.onUpdateApp }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("settings.update_app.update")) +
                          "\n      "
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isUpdating
            ? _c(
                "div",
                { staticClass: "relative flex justify-between mt-4 content" },
                [
                  _c("div", [
                    _c(
                      "h6",
                      { staticClass: "m-0 mb-3 font-medium sw-section-title" },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$t("settings.update_app.update_progress")
                            ) +
                            "\n        "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass: "mb-8 text-sm leading-snug text-gray-500",
                        staticStyle: { "max-width": "480px" }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$t("settings.update_app.progress_text")
                            ) +
                            "\n        "
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("loading-icon", {
                    staticClass:
                      "absolute right-0 h-6 m-1 animate-spin text-primary-400"
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isUpdating
            ? _c(
                "ul",
                { staticClass: "w-full p-0 list-none" },
                _vm._l(_vm.updateSteps, function(step) {
                  return _c(
                    "li",
                    {
                      staticClass:
                        "flex justify-between w-full py-3 border-b border-gray-200 border-solid last:border-b-0"
                    },
                    [
                      _c("p", { staticClass: "m-0 text-sm leading-8" }, [
                        _vm._v(_vm._s(_vm.$t(step.translationKey)))
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "flex flex-row items-center" }, [
                        step.time
                          ? _c(
                              "span",
                              { staticClass: "mr-3 text-xs text-gray-500" },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(step.time) +
                                    "\n          "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass:
                              "block py-1 text-sm text-center uppercase rounded-full",
                            class: _vm.statusClass(step),
                            staticStyle: { width: "88px" }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.getStatus(step)) +
                                "\n          "
                            )
                          ]
                        )
                      ])
                    ]
                  )
                }),
                0
              )
            : _vm._e()
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }