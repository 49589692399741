var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-full h-full" }, [
    _c(
      "div",
      { staticClass: "flex items-center justify-center w-full h-full" },
      [
        _c(
          "div",
          { staticClass: "flex flex-col items-center justify-center" },
          [
            _c(
              "h1",
              {
                staticClass: "text-primary-500",
                staticStyle: { "font-size": "10rem" }
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("general.four_zero_four")) +
                    "\n      "
                )
              ]
            ),
            _vm._v(" "),
            _c("h5", { staticClass: "mb-10 text-3xl text-primary-500" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("general.you_got_lost")) +
                  "\n      "
              )
            ]),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass:
                  "flex items-center w-32 h-12 px-2 py-1 text-base font-medium leading-none text-center text-white whitespace-nowrap rounded bg-primary-500 btn-lg hover:text-white",
                attrs: { to: "/admin/dashboard" }
              },
              [
                _c("arrow-left-icon", { staticClass: "mr-2 text-white icon" }),
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("general.go_home")) + "\n      "
                )
              ],
              1
            )
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }