var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "sw-wizard-step",
    {
      attrs: {
        title: _vm.$t("wizard.database.database"),
        description: _vm.$t("wizard.database.desc")
      }
    },
    [
      _vm.isFetching
        ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
        : _vm._e(),
      _vm._v(" "),
      _c(_vm.database_connection, {
        tag: "component",
        attrs: {
          "config-data": _vm.databaseData,
          "is-loading": _vm.isLoading,
          "is-fetching": _vm.isFetching
        },
        on: {
          "on-change-driver": _vm.getDatabaseConfig,
          "submit-data": _vm.next
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }