var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "sw-card",
    { attrs: { variant: "setting-card" } },
    [
      _c(
        "div",
        {
          staticClass: "flex flex-wrap justify-between lg:flex-nowrap",
          attrs: { slot: "header" },
          slot: "header"
        },
        [
          _c("div", [
            _c("h6", { staticClass: "sw-section-title" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("settings.tax_types.title")) +
                  "\n      "
              )
            ]),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "mt-2 text-sm leading-snug text-gray-500",
                staticStyle: { "max-width": "680px" }
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("settings.tax_types.description")) +
                    "\n      "
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt-4 lg:mt-0 lg:ml-2" },
            [
              _c(
                "sw-button",
                {
                  attrs: { size: "lg", variant: "primary-outline" },
                  on: { click: _vm.openTaxModal }
                },
                [
                  _c("plus-icon", { staticClass: "w-6 h-6 mr-1 -ml-2" }),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("settings.tax_types.add_new_tax")) +
                      "\n      "
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "sw-table-component",
        {
          ref: "table",
          attrs: {
            "show-filter": false,
            data: _vm.fetchData,
            "table-class": "table",
            variant: "gray"
          }
        },
        [
          _c("sw-table-column", {
            attrs: {
              sortable: true,
              label: _vm.$t("settings.tax_types.tax_name"),
              show: "name"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(row) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("settings.tax_types.tax_name")))
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "mt-6" }, [
                      _vm._v(_vm._s(row.name))
                    ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("sw-table-column", {
            attrs: {
              sortable: true,
              filterable: true,
              label: _vm.$t("settings.tax_types.compound_tax")
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(row) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("settings.tax_types.compound_tax")))
                    ]),
                    _vm._v(" "),
                    _c(
                      "sw-badge",
                      {
                        attrs: {
                          "bg-color": _vm.$utils.getBadgeStatusColor(
                            row.compound_tax ? "YES" : "NO"
                          ).bgColor,
                          color: _vm.$utils.getBadgeStatusColor(
                            row.compound_tax ? "YES" : "NO"
                          ).color
                        }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              row.compound_tax ? "Yes" : "No".replace("_", " ")
                            ) +
                            "\n        "
                        )
                      ]
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("sw-table-column", {
            attrs: {
              sortable: true,
              filterable: true,
              label: _vm.$t("settings.tax_types.percent")
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(row) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("settings.tax_types.percent")))
                    ]),
                    _vm._v("\n        " + _vm._s(row.percent) + " %\n      ")
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("sw-table-column", {
            attrs: {
              sortable: false,
              filterable: false,
              "cell-class": "action-dropdown"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(row) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("settings.tax_types.action")))
                    ]),
                    _vm._v(" "),
                    _c(
                      "sw-dropdown",
                      [
                        _c("dot-icon", {
                          attrs: { slot: "activator" },
                          slot: "activator"
                        }),
                        _vm._v(" "),
                        _c(
                          "sw-dropdown-item",
                          {
                            on: {
                              click: function($event) {
                                return _vm.editTax(row.id)
                              }
                            }
                          },
                          [
                            _c("pencil-icon", {
                              staticClass: "h-5 mr-3 text-gray-600"
                            }),
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("general.edit")) +
                                "\n          "
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "sw-dropdown-item",
                          {
                            on: {
                              click: function($event) {
                                return _vm.removeTax(row.id)
                              }
                            }
                          },
                          [
                            _c("trash-icon", {
                              staticClass: "h-5 mr-3 text-gray-600"
                            }),
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("general.delete")) +
                                "\n          "
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("sw-divider", { staticClass: "my-8" }),
      _vm._v(" "),
      _c("div", { staticClass: "flex mt-2" }, [
        _c(
          "div",
          { staticClass: "relative w-12" },
          [
            _c("sw-switch", {
              staticClass: "absolute",
              staticStyle: { top: "-20px" },
              on: { change: _vm.setTax },
              model: {
                value: _vm.formData.tax_per_item,
                callback: function($$v) {
                  _vm.$set(_vm.formData, "tax_per_item", $$v)
                },
                expression: "formData.tax_per_item"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "ml-4" }, [
          _c(
            "p",
            {
              staticClass:
                "p-0 mb-1 text-base leading-snug text-black box-title"
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("settings.tax_types.tax_per_item")) +
                  "\n      "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "p",
            {
              staticClass: "p-0 m-0 text-xs leading-4 text-gray-500",
              staticStyle: { "max-width": "480px" }
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("settings.tax_types.tax_setting_description")) +
                  "\n      "
              )
            ]
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }