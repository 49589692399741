var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "file-disk-modal" }, [
    _vm.getDiskDrivers.length
      ? _c(
          "div",
          [
            _c(_vm.selected_disk, {
              tag: "component",
              attrs: {
                loading: _vm.isLoading,
                disks: _vm.getDiskDrivers,
                "is-edit": _vm.isEdit
              },
              on: {
                "on-change-disk": function(disk) {
                  return (_vm.selected_disk = disk.value)
                },
                submit: _vm.createNewDisk
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function(slotProps) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass:
                              "z-0 flex justify-end p-4 border-t border-solid border-gray-light"
                          },
                          [
                            _c(
                              "sw-button",
                              {
                                staticClass: "mr-3 text-sm",
                                attrs: {
                                  variant: "primary-outline",
                                  type: "button"
                                },
                                on: { click: _vm.closeDisk }
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("general.cancel")) +
                                    "\n          "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "sw-button",
                              {
                                attrs: {
                                  loading: _vm.isRequestFire(slotProps),
                                  variant: "primary",
                                  disabled: _vm.isRequestFire(slotProps),
                                  type: "submit"
                                }
                              },
                              [
                                !_vm.isRequestFire(slotProps)
                                  ? _c("save-icon", { staticClass: "mr-2" })
                                  : _vm._e(),
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("general.save")) +
                                    "\n          "
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                100721560
              )
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }