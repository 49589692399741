var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "absolute top-0 left-0 z-20 flex items-center justify-center w-full h-full bg-opacity-25 base-loader",
      class: { "bg-gray-400": _vm.showBgOverlay }
    },
    [_vm._m(0)]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "absolute top-0 left-0 w-full h-full overlay" },
      [
        _c(
          "div",
          {
            staticClass:
              "absolute flex items-center justify-center ball-scale-ripple-multiple"
          },
          [_c("div"), _vm._v(" "), _c("div"), _vm._v(" "), _c("div")]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }