var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-page",
    { staticClass: "payments" },
    [
      _c(
        "sw-page-header",
        { attrs: { title: _vm.$t("payments.title") } },
        [
          _c(
            "sw-breadcrumb",
            { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
            [
              _c("sw-breadcrumb-item", {
                attrs: { to: "dashboard", title: _vm.$t("general.home") }
              }),
              _vm._v(" "),
              _c("sw-breadcrumb-item", {
                attrs: {
                  to: "#",
                  title: _vm.$tc("payments.payment", 2),
                  active: ""
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "template",
            { slot: "actions" },
            [
              _c(
                "sw-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.totalPayments,
                      expression: "totalPayments"
                    }
                  ],
                  attrs: { variant: "primary-outline", size: "lg" },
                  on: { click: _vm.toggleFilter }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("general.filter")) +
                      "\n        "
                  ),
                  _c(_vm.filterIcon, {
                    tag: "component",
                    staticClass: "w-4 h-4 ml-2 -mr-1"
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-button",
                {
                  staticClass: "ml-4",
                  attrs: {
                    "tag-name": "router-link",
                    to: "payments/create",
                    variant: "primary",
                    size: "lg"
                  }
                },
                [
                  _c("plus-icon", { staticClass: "w-6 h-6 mr-1 -ml-2" }),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("payments.add_payment")) +
                      "\n      "
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "slide-y-up-transition",
        [
          _c(
            "sw-filter-wrapper",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showFilters,
                  expression: "showFilters"
                }
              ],
              staticClass: "mt-3"
            },
            [
              _c(
                "sw-input-group",
                {
                  staticClass: "flex-1 mt-2",
                  attrs: {
                    label: _vm.$t("payments.customer"),
                    color: "black-light"
                  }
                },
                [
                  _c("base-customer-select", {
                    ref: "customerSelect",
                    on: {
                      select: _vm.onSelectCustomer,
                      deselect: _vm.clearCustomerSearch
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "flex-1 mt-2 lg:ml-6",
                  attrs: { label: _vm.$t("payments.payment_number") }
                },
                [
                  _c("sw-input", {
                    attrs: {
                      placeholder: _vm.$t(_vm.payments.payment_number),
                      name: "payment_number"
                    },
                    model: {
                      value: _vm.filters.payment_number,
                      callback: function($$v) {
                        _vm.$set(_vm.filters, "payment_number", $$v)
                      },
                      expression: "filters.payment_number"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "flex-1 mt-2 lg:ml-6",
                  attrs: { label: _vm.$t("payments.payment_mode") }
                },
                [
                  _c("sw-select", {
                    attrs: {
                      options: _vm.paymentModes,
                      searchable: true,
                      "show-labels": false,
                      placeholder: _vm.$t("payments.payment_mode"),
                      label: "name"
                    },
                    model: {
                      value: _vm.filters.payment_mode,
                      callback: function($$v) {
                        _vm.$set(_vm.filters, "payment_mode", $$v)
                      },
                      expression: "filters.payment_mode"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass:
                    "absolute text-sm leading-snug text-gray-900 cursor-pointer",
                  staticStyle: { top: "10px", right: "15px" },
                  on: { click: _vm.clearFilter }
                },
                [_vm._v(_vm._s(_vm.$t("general.clear_all")))]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.showEmptyScreen
        ? _c(
            "sw-empty-table-placeholder",
            {
              attrs: {
                title: _vm.$t("payments.no_payments"),
                description: _vm.$t("payments.list_of_payments")
              }
            },
            [
              _c("capsule-icon", { staticClass: "mt-5 mb-4" }),
              _vm._v(" "),
              _c(
                "sw-button",
                {
                  attrs: {
                    slot: "actions",
                    "tag-name": "router-link",
                    to: "/admin/payments/create",
                    size: "lg",
                    variant: "primary-outline"
                  },
                  slot: "actions"
                },
                [
                  _c("plus-icon", { staticClass: "w-6 h-6 mr-1 -ml-2" }),
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("payments.add_new_payment")) +
                      "\n    "
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showEmptyScreen,
              expression: "!showEmptyScreen"
            }
          ],
          staticClass: "relative table-container"
        },
        [
          _c(
            "div",
            {
              staticClass:
                "relative flex items-center justify-between h-10 mt-5 list-none border-b-2 border-gray-200 border-solid"
            },
            [
              _c("p", { staticClass: "text-sm" }, [
                _vm._v("\n        " + _vm._s(_vm.$t("general.showing")) + ": "),
                _c("b", [_vm._v(_vm._s(_vm.payments.length))]),
                _vm._v("\n        " + _vm._s(_vm.$t("general.of")) + " "),
                _c("b", [_vm._v(_vm._s(_vm.totalPayments))])
              ]),
              _vm._v(" "),
              _c(
                "sw-transition",
                { attrs: { type: "fade" } },
                [
                  _vm.selectedPayments.length
                    ? _c(
                        "sw-dropdown",
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "flex block text-sm font-medium cursor-pointer select-none text-primary-400",
                              attrs: { slot: "activator" },
                              slot: "activator"
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("general.actions")) +
                                  "\n            "
                              ),
                              _c("chevron-down-icon", { staticClass: "h-5" })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-dropdown-item",
                            { on: { click: _vm.removeMultiplePayments } },
                            [
                              _c("trash-icon", {
                                staticClass: "h-5 mr-3 text-gray-600"
                              }),
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("general.delete")) +
                                  "\n          "
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "absolute z-10 items-center pl-4 mt-2 select-none md:mt-12"
            },
            [
              _c("sw-checkbox", {
                staticClass: "hidden md:inline",
                attrs: { variant: "primary", size: "sm" },
                on: { change: _vm.selectAllPayments },
                model: {
                  value: _vm.selectAllFieldStatus,
                  callback: function($$v) {
                    _vm.selectAllFieldStatus = $$v
                  },
                  expression: "selectAllFieldStatus"
                }
              }),
              _vm._v(" "),
              _c("sw-checkbox", {
                staticClass: "md:hidden",
                attrs: {
                  label: _vm.$t("general.select_all"),
                  variant: "primary",
                  size: "sm"
                },
                on: { change: _vm.selectAllPayments },
                model: {
                  value: _vm.selectAllFieldStatus,
                  callback: function($$v) {
                    _vm.selectAllFieldStatus = $$v
                  },
                  expression: "selectAllFieldStatus"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-table-component",
            {
              ref: "table",
              attrs: {
                data: _vm.fetchData,
                "show-filter": false,
                "table-class": "table"
              }
            },
            [
              _c("sw-table-column", {
                attrs: {
                  sortable: false,
                  filterable: false,
                  "cell-class": "no-click"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(row) {
                      return _c(
                        "div",
                        { staticClass: "relative block" },
                        [
                          _c("sw-checkbox", {
                            attrs: {
                              id: row.id,
                              value: row.id,
                              variant: "primary",
                              size: "sm"
                            },
                            model: {
                              value: _vm.selectField,
                              callback: function($$v) {
                                _vm.selectField = $$v
                              },
                              expression: "selectField"
                            }
                          })
                        ],
                        1
                      )
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("payments.date"),
                  "sort-as": "payment_date",
                  show: "formattedPaymentDate"
                }
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("payments.payment_number"),
                  show: "payment_number"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("payments.payment_number")))
                        ]),
                        _vm._v(" "),
                        _c(
                          "router-link",
                          {
                            staticClass: "font-medium text-primary-500",
                            attrs: {
                              to: { path: "payments/" + row.id + "/view" }
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(row.payment_number) +
                                "\n          "
                            )
                          ]
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("payments.customer"),
                  show: "name"
                }
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("payments.payment_mode"),
                  show: "payment_mode"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("payments.payment_mode")))
                        ]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                row.payment_mode
                                  ? row.payment_mode
                                  : "Not selected"
                              ) +
                              "\n          "
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("payments.invoice"),
                  "sort-as": "invoice_id",
                  show: "invoice_number"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("invoices.invoice_number")))
                        ]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                row.invoice_number
                                  ? row.invoice_number
                                  : "No Invoice"
                              ) +
                              "\n          "
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: { sortable: true, label: _vm.$t("payments.amount") },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(row) {
                      return [
                        _c("span", [_vm._v(_vm._s(_vm.$t("payments.amount")))]),
                        _vm._v(" "),
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$utils.formatMoney(
                                row.amount,
                                row.user.currency
                              )
                            )
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: false,
                  filterable: false,
                  "cell-class": "action-dropdown"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(row) {
                      return [
                        _c("span", [_vm._v(_vm._s(_vm.$t("payments.action")))]),
                        _vm._v(" "),
                        _c(
                          "sw-dropdown",
                          [
                            _c("dot-icon", {
                              attrs: { slot: "activator" },
                              slot: "activator"
                            }),
                            _vm._v(" "),
                            _c(
                              "sw-dropdown-item",
                              {
                                attrs: {
                                  "tag-name": "router-link",
                                  to: "payments/" + row.id + "/edit"
                                }
                              },
                              [
                                _c("pencil-icon", {
                                  staticClass: "h-5 mr-3 text-gray-600"
                                }),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("general.edit")) +
                                    "\n            "
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "sw-dropdown-item",
                              {
                                attrs: {
                                  "tag-name": "router-link",
                                  to: "payments/" + row.id + "/view"
                                }
                              },
                              [
                                _c("eye-icon", {
                                  staticClass: "h-5 mr-3 text-gray-600"
                                }),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("general.view")) +
                                    "\n            "
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "sw-dropdown-item",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.removePayment(row.id)
                                  }
                                }
                              },
                              [
                                _c("trash-icon", {
                                  staticClass: "h-5 mr-3 text-gray-600"
                                }),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("general.delete")) +
                                    "\n            "
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }