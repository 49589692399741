var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "sw-wizard-step",
    {
      attrs: {
        title: _vm.$t("wizard.req.system_req"),
        description: _vm.$t("wizard.req.system_req_desc")
      }
    },
    [
      _c(
        "div",
        { staticClass: "w-full md:w-2/3" },
        [
          _c("div", { staticClass: "mb-6" }, [
            _vm.phpSupportInfo
              ? _c(
                  "div",
                  {
                    staticClass:
                      "grid grid-flow-row grid-cols-3 p-3 border border-gray-200 lg:gap-24 sm:gap-4"
                  },
                  [
                    _c("div", { staticClass: "col-span-2 text-sm" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t("wizard.req.php_req_version", {
                              version: _vm.phpSupportInfo.minimum
                            })
                          ) +
                          "\n        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "text-right" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.phpSupportInfo.current) +
                          "\n          "
                      ),
                      _vm.phpSupportInfo.supported
                        ? _c("span", {
                            staticClass:
                              "inline-block w-4 h-4 ml-3 mr-2 rounded-full bg-success"
                          })
                        : _c("span", {
                            staticClass:
                              "inline-block w-4 h-4 ml-3 mr-2 rounded-full bg-danger"
                          })
                    ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.requirements
              ? _c(
                  "div",
                  _vm._l(_vm.requirements, function(requirement, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass:
                          "grid grid-flow-row grid-cols-3 p-3 border border-gray-200 lg:gap-24 sm:gap-4"
                      },
                      [
                        _c("div", { staticClass: "col-span-2 text-sm" }, [
                          _vm._v(
                            "\n            " + _vm._s(index) + "\n          "
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "text-right" }, [
                          requirement
                            ? _c("span", {
                                staticClass:
                                  "inline-block w-4 h-4 ml-3 mr-2 rounded-full bg-success"
                              })
                            : _c("span", {
                                staticClass:
                                  "inline-block w-4 h-4 ml-3 mr-2 rounded-full bg-danger"
                              })
                        ])
                      ]
                    )
                  }),
                  0
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _vm.hasNext
            ? _c(
                "sw-button",
                {
                  staticClass: "mt-4 pull-right",
                  attrs: { variant: "primary" },
                  on: { click: _vm.next }
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t("wizard.continue")) + "\n      "
                  ),
                  _c("arrow-right-icon", { staticClass: "h-5 ml-2 -mr-1" })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.requirements
            ? _c(
                "sw-button",
                {
                  staticClass: "mt-4",
                  attrs: {
                    loading: _vm.isLoading,
                    disabled: _vm.isLoading,
                    variant: "primary"
                  },
                  on: { click: _vm.getRequirements }
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("wizard.req.check_req")) +
                      "\n    "
                  )
                ]
              )
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }