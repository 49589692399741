var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "footer",
      {
        staticClass:
          "absolute bottom-0 flex items-center justify-end w-full h-10 py-2 pr-8 text-sm font-normal text-gray-700 bg-white"
      },
      [
        _vm._v("\n  Powered By\n\n  "),
        _c(
          "a",
          {
            staticClass: "pl-1 font-normal text-gray-900",
            attrs: { href: "https://allianzeinfosoft.com/", target: "_blank" }
          },
          [_vm._v("\n    Allianze Infosoft\n  ")]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }