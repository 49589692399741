var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex-1 text-sm" },
    [
      _vm.item.item_id
        ? _c(
            "div",
            {
              staticClass:
                "relative flex items-center h-10 pl-2 bg-gray-200 border border-gray-200 border-solid rounded"
            },
            [
              _vm._v("\n    " + _vm._s(_vm.item.name) + "\n\n    "),
              _c(
                "span",
                {
                  staticClass: "absolute text-gray-400 cursor-pointer",
                  staticStyle: { top: "8px", right: "10px" },
                  on: { click: _vm.deselectItem }
                },
                [_c("x-circle-icon", { staticClass: "h-5" })],
                1
              )
            ]
          )
        : _c(
            "sw-select",
            {
              ref: "baseSelect",
              staticClass: "multi-select-item",
              attrs: {
                options: _vm.items,
                loading: _vm.loading,
                "show-labels": false,
                "preserve-search": true,
                "initial-search": _vm.item.name,
                invalid: _vm.invalid,
                placeholder: _vm.$t("estimates.item.select_an_item"),
                label: "name"
              },
              on: { value: _vm.onTextChange, select: _vm.onSelect },
              model: {
                value: _vm.itemSelect,
                callback: function($$v) {
                  _vm.itemSelect = $$v
                },
                expression: "itemSelect"
              }
            },
            [
              _c("div", { attrs: { slot: "afterList" }, slot: "afterList" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "flex items-center justify-center w-full p-3 bg-gray-200 border-none outline-none",
                    attrs: { type: "button" },
                    on: { click: _vm.openItemModal }
                  },
                  [
                    _c("shopping-cart-icon", {
                      staticClass: "h-5 text-primary-400"
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass:
                          "ml-2 text-sm leading-none text-primary-400"
                      },
                      [_vm._v(_vm._s(_vm.$t("general.add_new_item")))]
                    )
                  ],
                  1
                )
              ])
            ]
          ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "w-full pt-1 text-xs text-light" },
        [
          _c("sw-textarea", {
            directives: [{ name: "autoresize", rawName: "v-autoresize" }],
            staticClass: "w-full text-gray-600 border-none resize-none",
            attrs: {
              "invalid-description": _vm.invalidDescription,
              placeholder: _vm.$t("estimates.item.type_item_description"),
              type: "text",
              rows: "1",
              variant: "inv-desc"
            },
            on: {
              input: function($event) {
                return _vm.$emit("onDesriptionInput")
              }
            },
            model: {
              value: _vm.item.description,
              callback: function($$v) {
                _vm.$set(
                  _vm.item,
                  "description",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "item.description"
            }
          }),
          _vm._v(" "),
          _vm.invalidDescription
            ? _c("div", [
                _c("span", { staticClass: "text-danger" }, [
                  _vm._v(_vm._s(_vm.$tc("validation.description_maxlength")))
                ])
              ])
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }