var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "relative" },
    [
      _c(
        "div",
        { staticClass: "absolute bottom-0 right-0 z-10" },
        [
          _c(
            "sw-dropdown",
            {
              staticClass: "mb-2",
              attrs: {
                "close-on-select": true,
                "max-height": "220",
                position: "bottom-end"
              }
            },
            [
              _c(
                "sw-button",
                {
                  staticClass: "mr-2",
                  attrs: {
                    slot: "activator",
                    variant: "primary-outline",
                    type: "button"
                  },
                  slot: "activator"
                },
                [
                  _c("plus-sm-icon", { staticClass: "h-5 mr-1 -ml-2" }),
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t("settings.customization.addresses.insert_fields")
                      ) +
                      "\n      "
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex p-2" },
                _vm._l(_vm.fieldList, function(type, index) {
                  return _c(
                    "ul",
                    { key: index, staticClass: "list-none" },
                    [
                      _c(
                        "li",
                        {
                          staticClass:
                            "mb-1 ml-2 text-xs font-semibold text-gray-500 uppercase"
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(type.label) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm._l(type.fields, function(field, index) {
                        return _c(
                          "li",
                          {
                            key: index,
                            staticClass:
                              "w-48 text-sm font-normal cursor-pointer hover:bg-gray-200",
                            on: {
                              click: function($event) {
                                return _vm.insertField(field.value)
                              }
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "flex" },
                              [
                                _c("chevron-double-right-icon", {
                                  staticClass: "h-3 mt-1 text-gray-400"
                                }),
                                _vm._v(_vm._s(field.label) + "\n            ")
                              ],
                              1
                            )
                          ]
                        )
                      })
                    ],
                    2
                  )
                }),
                0
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("sw-editor", {
        staticClass: "text-area-field",
        attrs: {
          "set-editor": _vm.inputValue,
          disabled: _vm.disabled,
          invalid: _vm.isFieldValid,
          placeholder: _vm.placeholder,
          variant: "header-editor",
          "input-class": "border-none"
        },
        on: {
          input: _vm.handleInput,
          change: _vm.handleChange,
          keyup: _vm.handleKeyupEnter
        },
        model: {
          value: _vm.inputValue,
          callback: function($$v) {
            _vm.inputValue = $$v
          },
          expression: "inputValue"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }